import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import { FormProvider } from "./components/FormContext/FormContext";
import * as Sentry from "@sentry/react";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '6d605146-420e-41c6-8a68-732c2cae262d',
  clientToken: 'pubd9997b38534d2093ef44e58710dc714a',

  site: 'datadoghq.eu',
  service: 'hcp-portal-staging',
  env: 'staging',
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  defaultPrivacyLevel: 'mask-user-input',
});

Sentry.init({
  dsn: "https://dfdd0427ecb8bad3bc34cdf9c872b24f@o438923.ingest.us.sentry.io/4508646481592320",
  tracesSampleRate: 1.0, // Set to 1.0 for full capture; adjust for production
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <FormProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </FormProvider>
    </AuthProvider>
  </BrowserRouter>,
);

reportWebVitals();
