import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SearchForm from "../../stories/Forms/SearchForm";
import {
  searchFormFields,
  ConfirmationOneTubeFields,
  ConfirmationTwoTubeFields,
  links,
} from "../../stories/Forms/fields";
import { format_dt_age } from "../../utils/utils";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import Toaster from "../../stories/Toasts/Toaster";
import logo from "../../assets/images/mhc.png";
import img from "../../assets/images/notification.svg";
import LoggedInLayout from "../../stories/LoggedInScreens/LoggedInLayout";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

/**
 * ConfirmDetails component fetches and displays test confirmation details and allows
 * submission of test confirmation data.
 * @returns {JSX.Element} - Rendered ConfirmDetails component.
 */
function ConfirmDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Hook for API calls and managing state
  const {
    callApi,
    resultGet,
    message,
    isLoading,
    serverError,
    showError,
    tokenError,
    toggleShowError,
  } = useApis();

  // State management for various data
  const [userData, setUserData] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const getUrl = `/api/phlebotomists-portal/test-confirmation/${id}/`;
  const postUrl = `/api/phlebotomists-portal/test-confirmation/${id}/`;
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  // Get the authentication context
  const auth = useAuth();

  // Handle store selection change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const storeInfo = selectedOption
      ? selectedOption.label
      : selectedStore.length > 0
        ? selectedStore[0]?.store_info || ""
        : "";

    const [store_number, ...store_name_parts] = storeInfo.split(" ");
    const store_name = store_name_parts.join(" ").trim();

    const formattedData = {
      store_number,
      store_name,
    };

    await callApi(formattedData, postUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  useEffect(() => {
    /**
     * Fetches test confirmation data based on the id parameter.
     */
    auth.setTitle("PATIENT RECORD");
    const fetchData = async () => {
      await callApi("", getUrl, "GET", {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      });
    };

    fetchData();
  }, [id]);

  // Populate the form fields when resultGet is available
  useEffect(() => {
    if (resultGet) {
      const { user_info, booking_info, store_locations } = resultGet;

      setBookingData(booking_info);

      const formattedUserData = {
        ...user_info,
        dob: format_dt_age(user_info.dob),
      };

      setSelectedStore(resultGet.store_location_value);
      setUserData(formattedUserData);

      const storeOpts = store_locations.map((location) => ({
        value: location.store_info,
        label: location.store_info,
      }));
      setStoreOptions(storeOpts);
    }
    // Handle post submission response
    if (message?.postMessage) {
      navigate(`/enter-barcode/${id}`);
    } else {
      toggleShowError();
    }
  }, [resultGet, message]);

  const description = "Please confirm details to continue registration";

  // Navigates back to the previous page
  const handleBackClick = () => {
    navigate(-1);
  };

  // Navigates to appointment confirmation with rejection state
  const handleRejectClick = () => {
    navigate(`/appointment-confirmation/${id}`, {
      state: { rejectPath: true },
    });
  };

  return (
    <div className="test-confirmation-form">
      <LoggedInLayout
        buttonLabel={"Logout"}
        img={img}
        isActivation={false}
        links={links}
        logo={logo}
        pageTitle={auth.title}
        userInitials={auth.userInitials}
      >
        {isLoading && <p>Loading...</p>}
        {serverError && <p>Error: {serverError}</p>}
        <SearchForm
          data={userData}
          description={description}
          fields={searchFormFields}
        />
        <hr />
        <SearchForm
          buttonGroup={true}
          className={"custom-test-confirmation-grid"}
          colStructure={3}
          data={bookingData}
          fields={(resultGet?.booking_info?.vessel_info_2
            ? ConfirmationTwoTubeFields
            : ConfirmationOneTubeFields
          ).map((field) =>
            field.name === "location_store"
              ? { ...field, options: storeOptions }
              : field,
          )}
          onBackClick={handleBackClick}
          onRejectClick={handleRejectClick}
          onSelectChange={handleSelectChange}
          onSubmit={handleSubmit}
          storeName={selectedStore}
        />
        {message?.postMessage && (
          auth?.showToast(
            "Confirm user details",
            "User details confirmed!",
            "../assets/mhc.png",
            <ArrowRightCircleFill />
          )
        )}
        {(serverError || tokenError) && (
          <Toaster
            body={tokenError ? tokenError : serverError}
            header="Error"
            onClose={toggleShowError}
            position="top-end"
            show={tokenError ? tokenError : showError}
          />
        )}
      </LoggedInLayout>
    </div>
  );
}

export default ConfirmDetails;
