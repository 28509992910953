import unionIcon from "../../assets/images/union.svg";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import icon from "../../assets/images/icon.svg";
import barcodeImage from "../../assets/images/barcode.png";
import barcodeLavenderImage from "../../assets/images/barcode-lavender.png";
import magnifyingIcon from "../../assets/images/magnifying-glass.svg";
 
export const activationFields = {
  rows: [
    [
      {
        controlId: "1",
        label: "First name",
        type: "text",
        name: "first_name",
        as: "",
        required: true,
        placeholder: "First name",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val1",
        className: "form-group",
      },
      {
        controlId: "2",
        label: "Last name",
        type: "text",
        name: "last_name",
        as: "",
        required: true,
        placeholder: "Last name",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val2",
        className: "form-group",
      },
    ],
    [
      {
        controlId: "3",
        label: "Occupation",
        type: "select",
        options: [
          "Clinician",
          "Dentist",
          "Dietitian",
          "Doctor",
          "Nurse",
          "Nutritionist",
          "Phlebotomist",
        ],
        name: "occupation",
        as: "",
        required: true,
        placeholder: "Choose occupation",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val2",
      },
      {
        controlId: "4",
        label: "Credentials",
        type: "text",
        name: "credentials",
        as: "textarea",
        draggable: false,
        required: false,
        placeholder: "Enter credentials",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val4",
        className: "form-group",
      },
    ],
    [
      {
        controlId: "5",
        label: "Organisation",
        type: "text",
        name: "organisation",
        as: "",
        required: false,
        placeholder: "Enter organisation",
        size: "text",
        readOnly: true,
        disabled: false,
        aria_describedby: "val5",
        className: "form-group",
      },
      {
        controlId: "6",
        label: "Mobile",
        type: "tel",
        name: "mobile",
        maxLength: 11,
        as: "",
        required: false,
        placeholder: "0000 0000 0000",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val6",
        className: "form-group",
      },
    ],
    [
      {
        controlId: "7",
        label: "Email",
        type: "email",
        name: "email",
        as: "",
        required: true,
        placeholder: "Enter email",
        size: "text",
        readOnly: true,
        disabled: false,
        aria_describedby: "val7",
        className: "form-group",
      },
      {
        controlId: "8",
        label: "Password",
        type: "password",
        name: "password",
        as: "",
        required: true,
        placeholder: "Create password",
        size: "text",
        readOnly: false,
        disabled: false,
        aria_describedby: "val8",
        className: "form-group",
        passHints: [
          "One uppercase character",
          "One special character",
          "One number",
          "One lowercase character",
          "Mininum 8 characters",
        ],
        helpText: "",
      },
      {
        controlId: "9",
        name: "button",
        label: "CREATE ACCOUNT",
        type: "button",
        variant: "light-gray",
      },
    ],
  ],
};
 
export const loginFields = [
  {
    controlId: "1",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "Enter your email address",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
  },
  {
    controlId: "2",
    label: "Password",
    type: "password",
    name: "password",
    as: "",
    required: true,
    placeholder: "Enter your password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
    links: (
      <div className="">
        <a className="dark-grey-text" href="/forgot-password">
          forgot password?
        </a>
      </div>
    ),
  },
  {
    controlId: "3",
    label: "Login",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn m-2",
  },
];
 
export const forgotPasswordFields = [
  {
    controlId: "1",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "Enter your email address...",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
    links: (
      <div className="dark-grey-text">
        Know your password?{" "}
        <a className=" text-secondary" href="/login">
          Log in
        </a>
      </div>
    ),
  },
  {
    controlId: "3",
    label: "Reset password",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn m-2",
  },
];
 
export const confirmMfaFields = [
  {
    controlId: "1",
    label: "Confirm OTP",
    type: "number",
    name: "otp",
    as: "",
    required: true,
    placeholder: "Enter your auth code",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
  },
  {
    controlId: "3",
    label: "Confirm OTP",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn m-2",
  },
];
 
export const enableMfaFields = [
  {
    controlId: "1",
    label: "Code",
    type: "number",
    name: "otp",
    as: "",
    required: true,
    placeholder: "Enter your auth code",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
    helpText: `Once you've scanned the QR code, enter the 6 digit code displayed to confirm you have the MFA stored successfully.`,
  },
  {
    controlId: "3",
    label: "Enable MFA",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn w-100",
  },
];
 
export const resetPasswordFields = [
  {
    controlId: "1",
    label: "New password",
    type: "password",
    name: "password",
    as: "",
    required: true,
    placeholder: "Enter you password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "2",
    label: "Confirm password",
    type: "password",
    name: "confirmpassword",
    as: "",
    required: true,
    placeholder: "Confirm your password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "3",
    label: "Reset password",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn m-2",
  },
];
 
export const changePasswordFields = [
  {
    controlId: "1",
    label: "Current password",
    type: "password",
    name: "current_password",
    as: "",
    required: true,
    placeholder: "Enter your current password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "2",
    label: "New password",
    type: "password",
    name: "new_password",
    as: "",
    required: true,
    placeholder: "Enter your new password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "3",
    label: "Confirm password",
    type: "password",
    name: "confirm_password",
    as: "",
    required: true,
    placeholder: "Confirm your password",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "4",
    label: "Change password",
    type: "submit",
    size: "medium",
    variant: "primary",
    className: "btn mt-2",
  },
];
 
export const links = [
  {
    text: "Patient record",
    icon: calendarIcon,
    alt: "Patient record icon",
    href: "/patient-record",
  },
  { text: "Account", icon: unionIcon, alt: "Account icon", href: "/account" },
  {
    text: "Resources",
    icon: icon,
    alt: "Resources icon",
    href: "/resources",
  },
];
 
export const accountFields = [
  {
    controlId: "1",
    label: "First name",
    type: "text",
    name: "first_name",
    as: "",
    required: true,
    placeholder: "Enter your first name",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
  },
  {
    controlId: "2",
    label: "Last name",
    type: "text",
    name: "last_name",
    as: "",
    required: true,
    placeholder: "Enter your last name",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group",
  },
  {
    controlId: "3",
    label: "Occupation",
    type: "selectValue",
    options: [
      { value: "clinician", label: "Clinician" },
      { value: "dentist", label: "Dentist" },
      { value: "dietitian", label: "Dietitian" },
      { value: "doctor", label: "Doctor" },
      { value: "nurse", label: "Nurse" },
      { value: "nutritionist", label: "Nutritionist" },
      { value: "phlebotomist", label: "Phlebotomist" },
    ],
    name: "occupation",
    as: "",
    required: true,
    placeholder: "Enter your occupation",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
  },
  {
    controlId: "4",
    label: "Credentials",
    type: "text",
    name: "credentials",
    as: "textarea",
    draggable: false,
    placeholder: "Enter your credentials",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val3",
    className: "form-group",
    required: false,
  },
  {
    controlId: "5",
    label: "Organisation",
    type: "text",
    name: "phlebotomist_org",
    as: "",
    required: false,
    placeholder: "Enter your organisation",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val5",
    className: "form-group",
  },
  {
    controlId: "6",
    label: "Mobile",
    type: "tel",
    name: "phone_number_primary",
    as: "",
    required: false,
    maxLength: 11,
    placeholder: "Enter your mobile number",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val4",
    className: "form-group",
  },
  {
    controlId: "7",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "Enter your email",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val6",
    className: "form-group",
  },
];
 
export const patientRecordsFields = [
  {
    label: "First name",
    type: "text",
    name: "first_name",
    placeholder: "First name",
    required: false,
  },
  {
    label: "Last name",
    type: "text",
    name: "last_name",
    placeholder: "Last name",
    required: false,
  },
  {
    label: "D.O.B",
    type: "date",
    name: "dob",
    placeholder: "DD/MM/YYYY",
    required: false,
    className: "birth-date-field",
  },
  {
    label: "Email",
    type: "email",
    name: "email",
    placeholder: "myname@example.com",
    readOnly: false,
    required: false,
  },
  {
    label: "Phone",
    type: "tel",
    name: "phone_number_primary",
    placeholder: "0000 000 000",
    required: false,
    maxLength: 11,
  },
  {
    label: "Booking ID",
    type: "text",
    name: "booking_id",
    placeholder: "AB1234",
    required: false,
  },
  {
    label: "Search",
    type: "submit",
    variant: "primary",
    name: "button",
    required: false,
    icon: magnifyingIcon,
    iconOrder: "On right",
    className: "w-100",
  },
];
 
export const searchFormFields = [
  {
    label: "Patient name",
    name: "full_name",
    type: "text",
    placeholder: "Enter patient name...",
    required: false,
    readOnly: true,
  },
  {
    label: "D.O.B",
    name: "dob",
    type: "text",
    required: false,
    readOnly: true,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter email address...",
    required: false,
    readOnly: true,
  },
  {
    label: "Phone",
    name: "phone_number_primary",
    type: "tel",
    placeholder: "Enter phone number...",
    required: false,
    readOnly: true,
  },
];
 
export const ConfirmationOneTubeFields = [
  {
    label: "Test type",
    type: "text",
    name: "test_type",
    placeholder: "",
    readOnly: true,
    required: false,
  },
  {
    label: "Vessel type",
    type: "text",
    name: "vessel_info_1",
    className:"vessel_info_1",
    placeholder: "",
    readOnly: true,
    required: false,
  },
  {
    label: "Repeated test",
    type: "boolean",
    name: "repeated_test",
    placeholder: "",
    readOnly: true,
    required: false,
  },
  {
    label: "Location store",
    type: "dropdown",
    name: "location_store",
    placeholder: "Please choose store location",
    className: "location-dropdown form-group",
    readOnly: false,
    required: false,
  },
  {
    btnGroup: [
      {
        label: "Back",
        type: "button",
        variant: "outline",
        name: "back",
        required: false,
        className: "ms-auto",
      },
      {
        label: "Save & continue",
        type: "submit",
        href: "/enter-barcode/${booking.phlebotomist_booking_id}",
        variant: "secondary",
        name: "button",
        required: false,
      },
    ],
  },
];
 
export const ConfirmationTwoTubeFields = [
  {
    label: "Test type",
    type: "text",
    placeholder: "Test type",
    name: "test_type",
    required: false,
    colSize: 2,
  },
  {
    label: "Vessel type 1",
    type: "text",
    placeholder: "",
    name: "vessel_info_1",
    className:"vessel_info_1",
    readOnly: true,
    required: false,
    colSize: 2,
  },
  {
    label: "Vessel type 2", 
    type: "text",
    className: "additional-vessel-type",
    placeholder: "Vessel type",
    name: "vessel_info_2",
    readOnly: true,
    required: false,
    colSize: 2,
  },
  {
    label: "Repeated test",
    type: "boolean",
    placeholder: "",
    name: "repeated_test",
    size: "text",
    readOnly: true,
    required: false,
    colSize: 2,
  },
  {
    label: "Location store",
    type: "dropdown",
    name: "location_store",
    placeholder: "Please choose store location",
    readOnly: false,
    required: false,
    colSize: 4,
  },
  {
    btnGroup: [
      {
        label: "Back",
        type: "button",
        variant: "outline",
        name: "back",
        required: false,
        colSize: 2,
      },
      {
        label: "Save & continue",
        type: "submit",
        variant: "secondary",
        name: "button",
        required: false,
        colSize: 2,
      },
    ],
  },
];
 
export const BarcodeOneTubeFields = [
  {
    label: "Test type",
    type: "text",
    name: "test_type",
    readOnly: true,
    colSize: 12,
    className: "test-type-field form-group",
  },
  {
    label: "",
    type: "text",
    name: "primary_barcode",
    placeholder: "PGK-xxx-1234",
    required: true,
    helpTextAbove: `<span class="underline-text">1. <span class="danger-text">Before</span> drawing blood</span>, enter the kit barcode number <span class='my-3 d-flex'>Please enter the 10-character SST (GOLD) barcode for the specific test type</span>`,
    helpText: "Expire date:",
    FormFieldImage: barcodeImage,
    colSize: 8,
    hrAfter: true,
    textAfter: true,
    className: "form-group barcode-tubes",
    maxLength: 12,
  },
  {
    label: "Date",
    type: "date",
    name: "sample_collection_date",
    readOnly: false,
    required: false,
    className: "",
    maxLength: 8,
    colSize: 4,
  },
  {
    label: "Time",
    type: "time",
    name: "sample_collection_time",
    placeholder: "00:00",
    readOnly: false,
    required: false,
    colSize: 4,
  },
 
  {
    label: "No. of attempts",
    placeholder: "Select",
    options: ["0", "1", "2"],
    type: "select",
    name: "number_of_attempt",
    readOnly: false,
    required: false,
    colSize: 4,
    hrAfter: true,
  },
  {
    label: "",
    placeholder: "Notes",
    as: "textarea",
    draggable: false,
    type: "notes",
    name: "notes",
    className: "notes-textarea",
    readOnly: false,
    required: false,
    colSize: 6,
  },
  {
    btnGroup: [
      {
        label: "Reject",
        type: "button",
        variant: "danger",
        name: "button",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
      {
        label: "Back",
        type: "button",
        variant: "outline",
        name: "back",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
      {
        label: "Save & continue",
        type: "submit",
        variant: "secondary",
        name: "button",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
    ],
  },
];
 
export const BarcodeTwoTubeFields = [
  {
    label: "Test type",
    type: "text",
    name: "test_type",
    placeholder: "Enter Test type",
    readOnly: true,
    className: "test-type-field form-group",
    colSize: 12,
  },
  {
    label: "",
    type: "barcode-text",
    helpTextAbove: `<span class="underline-text">1. <span class="danger-text">Before</span> drawing blood</span>`,
    FormFieldImage: barcodeImage,
    colSize: 12,
  },
  {
    label: "",
    type: "text",
    name: "primary_barcode",
    placeholder: "PGK-abc-1234",
    required: false,
    helpText: "Expire date:",
    helpTextAbove: `<p>Please enter the 10-character SST (GOLD)<br>  barcode for the specific test type</p>`,
    FormFieldImage: barcodeImage,
    className: "form-group barcode-tubes",
    colSize: 6,
    maxLength: 12,
  },
  {
    label: "",
    type: "text",
    name: "secondary_barcode",
    placeholder: "PLK-abc-1234",
    required: false,
    helpTextAbove:
      "<p> Please check the 10-character EDTA<br> (LAVENDER) barcode for the specific test type </p>",
    FormFieldImage: barcodeLavenderImage,
    readOnly: true,
    colSize: 6,
    className: "form-group barcode-tubes",
    hrAfter: true,
    textAfter: true,
  },
  {
    label: "Date",
    type: "date",
    name: "sample_collection_date",
    placeholder: "DD/MM/YYYY",
    readOnly: false,
    required: false,
    maxLength: 8,
    colSize: 2,
  },
  {
    label: "Time",
    type: "time",
    name: "sample_collection_time",
    placeholder: "00:00",
    readOnly: false,
    required: false,
    colSize: 2,
  },
  {
    label: "No. of attempts",
    type: "select",
    name: "number_of_attempt",
    placeholder: "Select",
    options: ["0", "1", "2"],
    readOnly: false,
    required: false,
    colSize: 2,
    hrAfter: true,
  },
  {
    label: "",
    placeholder: "Notes",
    className: "notes-textarea",
    as: "textarea",
    draggable: false,
    type: "notes",
    name: "notes",
    readOnly: false,
    required: false,
    colSize: 6,
  },
  {
    btnGroup: [
      {
        label: "Reject",
        type: "button",
        variant: "danger",
        name: "button",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
      {
        label: "Back",
        type: "button",
        variant: "outline",
        name: "back",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
      {
        label: "Save & continue",
        type: "submit",
        variant: "secondary",
        name: "button",
        required: false,
        className: "semi-buttons",
        size: "small",
      },
    ],
  },
];
 
export const testKitRegistrationFields = [
  {
    label: "",
    placeholder: "Notes",
    as: "textarea",
    type: "text-area",
    name: "notes",
    className: "notes-textarea",
    required: false,
    colSize: 6,
    readOnly: true,
  },
  {
    btnGroup: [
      {
        label: "BACK",
        type: "button",
        variant: "outline",
        name: "back",
        required: false,
        className: "ms-auto",
        size: "small",
        colSize: 2,
      },
      {
        label: "EDIT",
        type: "button",
        variant: "danger",
        name: "button",
        required: false,
        className: "ms-auto",
        size: "small",
        colSize: 2,
      },
      {
        label: "REGISTER",
        type: "submit",
        variant: "secondary",
        name: "button",
        required: false,
        size: "small",
        colSize: 2,
      },
    ],
  },
];
 
export const testKitRejectionFields = [
  {
    label: "Select reason for kit rejection",
    type: "select",
    name: "rejection_reason",
    options: [
      "Barcode(s) wasted",
      "Kit expired",
      "Missing components",
      "Other(s)",
    ],
    placeholder: "Choose reason",
    readOnly: false,
    required: false,
    colSize: 5,
    hrAfter: true,
    className: "rejection-select",
  },
  {
    label: "",
    placeholder: "Notes",
    as: "textarea",
    type: "text",
    name: "notes",
    className: "notes-textarea",
    readOnly: false,
    required: true,
    colSize: 5,
  },
  {
    btnGroup: [
      {
        label: "Reject",
        type: "submit",
        variant: "danger",
        name: "button",
        required: false,
        className: "ms-auto",
        size: "small",
        colSize: 2,
      },
      {
        label: "Cancel",
        type: "button",
        variant: "secondary",
        name: "button",
        required: false,
        size: "small",
        colSize: 2,
      },
    ],
  },
];
 
export const testApptRejectionFields = [
  {
    label: "Select reason for appointment rejection",
    type: "select",
    name: "rejection_reason",
    options: [
      "Fainters",
      "Feeling faint",
      "Adverse reaction",
      "Needle stick injuries",
    ],
    placeholder: "Choose reason",
    readOnly: false,
    required: false,
    colSize: 5,
    hrAfter: true,
    className: "rejection-select",
  },
  {
    label: "",
    placeholder: "Notes",
    as: "textarea",
    type: "text",
    name: "notes",
    className: "notes-textarea",
    readOnly: false,
    required: true,
    colSize: 5,
  },
  {
    btnGroup: [
      {
        label: "Reject",
        type: "submit",
        variant: "danger",
        name: "button",
        required: false,
        className: "ms-auto",
        size: "small",
        colSize: 2,
      },
      {
        label: "Cancel",
        type: "button",
        variant: "secondary",
        name: "button",
        required: false,
        size: "small",
        colSize: 2,
      },
    ],
  },
];
